import { BASE_PATH_CONTENT } from '@core/services/internals/tour.service';
import { readContentHtmlFile } from '@modules/static-content/services/static-content.service';
import Step from 'shepherd.js/src/types/step';
import { mainMenuMap } from '../config';

export const LANDING_TOUR: (isAuth: boolean) => Step.StepOptions[] = isAuth => [
  {
    attachTo: {
      element: '#register-button-desktop',
    },
    id: '01-se-parte-de-tribuu',
    showOn: () => !isAuth,
    // buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.cancel, STEPS_BUTTONS.next],
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      addTargetArrow('register-button-desktop', 'right');
    },
  },
  {
    id: '02-visualiza-tu-perfil',
    modalOverlayOpeningPadding: 8,
    attachTo: {
      element: '#login-button-desktop',
    },
    showOn: () => !isAuth,
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow('login-button-desktop', 'right');
    },
  },
  {
    id: '03-elige-la-modalidad',
    attachTo: {
      element: `#${mainMenuMap.profile.id}`,
    },
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow(mainMenuMap.profile.id);
    },
  },
  {
    attachTo: {
      element: `#${mainMenuMap.hire.id}`,
    },
    id: '04-eres-reclutador',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow(mainMenuMap.hire.id);
    },
  },
  {
    attachTo: {
      element: `#${mainMenuMap.pricing.id}`,
    },
    id: '05-adquiere-creditos',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow(mainMenuMap.pricing.id);
    },
  },
  {
    attachTo: {
      element: `#${mainMenuMap.sessions.id}`,
    },
    id: '06-sesiones',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;

      deleteMockstep();
      if (!document.getElementById(mainMenuMap.sessions.id)) createMockStepSix();
      addTargetArrow(mainMenuMap.sessions.id, 'right');
    },
  },
  {
    attachTo: {
      element: '#profile-user-menu-desktop',
    },
    id: '07-user-menu',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      if (!document.getElementById('profile-user-menu-desktop')) createMockStepSeven();
      addTargetArrow('profile-user-menu-desktop', 'right');
    },
  },
  {
    attachTo: {
      element: `#sticky-search`,
      on: 'bottom',
    },
    classes: 'translate-y-8',
    id: '08-buscas-un-candidato',
    beforeShowPromise: async function () {
      deleteMockstep();
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      window.scrollBy({ top: 800, left: 0, behavior: 'smooth' });
      await new Promise(resolve => setTimeout(resolve, 800));
      createArrowElement({ bottom: 930, left: 80, orientation: 'left' });
    },
  },
  {
    id: '09-ya-eres-parte-de-la-tribuu',
    showOn: () => !isAuth,
    // buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.cancel, STEPS_BUTTONS.next],
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
    },
  },
];

export const LANDING_TOUR_MOBILE: (isAuth: boolean) => Step.StepOptions[] = isAuth => [
  {
    attachTo: {
      element: '#register-button-mobile',
    },
    id: '01-se-parte-de-tribuu',
    showOn: () => !isAuth,
    // buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.cancel, STEPS_BUTTONS.next],
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      addTargetArrow('register-button-mobile', 'right');
    },
  },
  {
    id: '02-visualiza-tu-perfil',
    modalOverlayOpeningPadding: 8,
    attachTo: {
      element: '#login-button-mobile',
    },
    showOn: () => !isAuth,
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow('login-button-mobile');
    },
  },
  {
    id: '03-elige-la-modalidad',
    attachTo: {
      element: `#mainMenuMobile`,
    },
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow('mainMenuMobile');
    },
  },
  {
    attachTo: {
      element: `#mainMenuMobile`,
    },
    id: '04-eres-reclutador',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow('mainMenuMobile');
    },
  },
  {
    attachTo: {
      element: `#mainMenuMobile`,
    },
    id: '05-adquiere-creditos',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow('mainMenuMobile');
    },
  },
  {
    attachTo: {
      element: '#mainMenuMobile',
    },
    id: '06-sesiones',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;

      deleteMockstep();
      addTargetArrow('mainMenuMobile');
    },
  },
  {
    attachTo: {
      element: '#mainMenuMobile',
    },
    id: '07-user-menu',
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
      addTargetArrow('mainMenuMobile');
    },
  },
  {
    attachTo: {
      element: `#sticky-search`,
      on: 'bottom',
    },
    classes: 'translate-y-8',
    id: '08-buscas-un-candidato',
    beforeShowPromise: async function () {
      deleteMockstep();
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      window.scrollBy({ top: 800, left: 0, behavior: 'smooth' });
      await new Promise(resolve => setTimeout(resolve, 800));
    },
  },
  {
    id: '09-ya-eres-parte-de-la-tribuu',
    showOn: () => !isAuth,
    // buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.cancel, STEPS_BUTTONS.next],
    beforeShowPromise: async function () {
      const htmlString = await readContentHtmlFile(`${BASE_PATH_CONTENT}${this.id}`);
      this.text = htmlString;
      deleteMockstep();
    },
  },
];

function createMockStepSix() {
  const navElement = document.getElementById('desktopnav');
  if (!navElement) return;
  const menuItem = document.createElement('div');
  menuItem.id = mainMenuMap.sessions.id;
  menuItem.classList.add(...'isMockElement w-28 h-10 bg-white flex items-center gap-1'.split(' '));

  const imageElement = document.createElement('img');
  imageElement.src = 'assets/images/icons/menu/session.svg';
  imageElement.style.width = '25px';

  menuItem.appendChild(imageElement);
  menuItem.innerHTML += 'Sesiones';

  navElement.appendChild(menuItem);
}

function createMockStepSeven() {
  const divNoAuth = document.getElementById('divnoauth');
  if (!divNoAuth) return;
  const imageElement = document.createElement('img');
  imageElement.id = 'profile-user-menu-desktop';
  imageElement.src = 'assets/images/tutorial/mock-profile.png';
  imageElement.classList.add(
    ...'isMockElement w-full h-full bg-white object-contain absolute top-0 left-0'.split(' ')
  );

  divNoAuth.appendChild(imageElement);
}

function deleteMockstep() {
  document.querySelectorAll('.isMockElement').forEach(element => {
    if (!element.parentNode) return;
    element.parentNode.removeChild(element);
  });
}

function addTargetArrow(idElementTarget: string, orientation: 'left' | 'right' = 'left') {
  const mainElement = document.getElementById(idElementTarget);
  if (!mainElement) return;
  const { bottom, left } = mainElement.getBoundingClientRect();
  createArrowElement({ bottom, left, orientation });
}

function createArrowElement(position: {
  bottom: number;
  left: number;
  orientation: 'left' | 'right';
}) {
  const arrowElement = document.createElement('img');

  arrowElement.classList.add(
    ...'isMockElement w-28 absolute z-[10000] fade-in-app-fast transition-all'.split(' ')
  );
  arrowElement.style.top = `${position.bottom + 20}px`;
  arrowElement.style.left = `${position.left}px`;
  arrowElement.src =
    position.orientation == 'left'
      ? 'assets/images/tutorial/tour-arrow-left.png'
      : 'assets/images/tutorial/tour-arrow-right.png';

  document.body.appendChild(arrowElement);
}
