import {
  Input,
  ChangeDetectionStrategy,
  Component,
  Signal,
  ViewChild,
  computed,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthActions } from '@core/store/auth/auth.actions';
import { ProfileState } from '@core/store/profile/profile.state';
import { TriberState } from '@core/store/triber/triber.state';
import { EMPTY_STRING } from '@data/constants/routes';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { TuiDropdownDirective } from '@taiga-ui/core';

@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuUserComponent {
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  userInfo: Signal<{ name: string; img?: string } | null> = computed(() => {
    const isTrberLoaded = this.store.selectSignal(TriberState.loaded)();
    if (!isTrberLoaded) return null;
    const triber = this.store.selectSignal(TriberState.triber)();
    if (triber) {
      return { name: triber.name, img: triber.imageUrl };
    }
    const profile = this.store.selectSnapshot(ProfileState.profile);
    return {
      name: profile?.name || profile?.email || 'Desconocido',
      img: profile?.imageUrl || environment.defaultImageUrl,
    };
  });

  @ViewChild('dropdown') dropdown!: TuiDropdownDirective;

  logout() {
    this.store.dispatch(new AuthActions.Logout());
    this.router.navigateByUrl(EMPTY_STRING, { replaceUrl: true });
  }
}
