<ng-container>
  <tui-badged-content
    size="l"
    [contentTop]="unreadCount()"
    [colorTop]="unreadCount() ? '#ff7875' : ''">
    <button
      class="hover:bg-neutral-100 flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-gray-500 transition-all duration-300 hover:text-gray-900"
      [ngClass]="{
        'heartbeat border-2 border-red-600 bg-red-100 text-red-600': unreadCount()
      }"
      nz-dropdown
      nzPlacement="bottomRight"
      nzTrigger="click"
      (nzVisibleChange)="readNotifications($event)"
      [nzDropdownMenu]="dropdownNotifications"
      [nzVisible]="dropdownVisible()"
      aria-current="page">
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        class="h-full w-full"
        xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M6.50248 6.97519C6.78492 4.15083 9.16156 2 12 2C14.8384 2 17.2151 4.15083 17.4975 6.97519L17.7841 9.84133C17.8016 10.0156 17.8103 10.1028 17.8207 10.1885C17.9649 11.3717 18.3717 12.5077 19.0113 13.5135C19.0576 13.5865 19.1062 13.6593 19.2034 13.8051L20.0645 15.0968C20.8508 16.2763 21.244 16.866 21.0715 17.3412C21.0388 17.4311 20.9935 17.5158 20.9368 17.5928C20.6371 18 19.9283 18 18.5108 18H5.48923C4.07168 18 3.36291 18 3.06318 17.5928C3.00651 17.5158 2.96117 17.4311 2.92854 17.3412C2.75601 16.866 3.14916 16.2763 3.93548 15.0968L4.79661 13.8051C4.89378 13.6593 4.94236 13.5865 4.98873 13.5135C5.62832 12.5077 6.03508 11.3717 6.17927 10.1885C6.18972 10.1028 6.19844 10.0156 6.21587 9.84133L6.50248 6.97519Z"
            fill="currentColor"></path>
          <path
            d="M10.0681 20.6294C10.1821 20.7357 10.4332 20.8297 10.7825 20.8967C11.1318 20.9637 11.5597 21 12 21C12.4403 21 12.8682 20.9637 13.2175 20.8967C13.5668 20.8297 13.8179 20.7357 13.9319 20.6294"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"></path>
        </g>
      </svg>
    </button>
  </tui-badged-content>
  <nz-dropdown-menu #dropdownNotifications>
    <div
      class="border-custom max-h-[30em] divide-y overflow-y-auto rounded bg-white drop-shadow-lg">
      <ng-container *ngIf="notifications().length; else empty">
        <div
          class="w-full max-w-xs p-4 text-gray-900"
          role="alert"
          *ngFor="let n of notifications()">
          <div class="mb-2 flex items-center">
            <span class="text-right text-xs font-medium text-blue-600" *ngIf="n.createdAt">
              {{ n.createdAt | dateAgo }}
            </span>
            <button
              (click)="removeNotification(n.uid)"
              type="button"
              class="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900"
              data-dismiss-target="#toast-notification"
              aria-label="Close">
              <span class="sr-only">Close</span>
              <svg
                aria-hidden="true"
                class="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
            </button>
          </div>
          <div class="flex items-start">
            <div class="relative inline-block shrink-0">
              <img
                *ngIf="n.image"
                class="h-12 w-12 rounded-full object-cover"
                [src]="n.image"
                alt="imagen de notificacion" />
              <span
                *ngIf="n.icon"
                class="absolute bottom-0 right-0 inline-flex h-6 w-6 items-center justify-center rounded-full bg-pink-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-3 w-3 text-white">
                  <path
                    d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                </svg>
                <span class="sr-only">Message icon</span>
              </span>
            </div>
            <div class="ml-3 text-sm font-normal">
              <div class="mb-1 text-sm font-semibold text-gray-900">
                {{ n.title }}
              </div>
              <div class="text-sm font-normal">
                {{ n.body }}
              </div>
              <div class="mt-3 flex justify-end px-1" *ngIf="n.btnLabel">
                <a
                  class="text-sm"
                  (click)="goto(n.btnRedirect)"
                  tribuuButton
                  size="sm"
                  theme="primary">
                  {{ n.btnLabel }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </nz-dropdown-menu>

  <ng-template #empty>
    <div class="flex min-h-[15rem] w-[20rem] flex-col">
      <div class="flex flex-auto flex-col items-center justify-center p-4 md:p-5">
        <svg
          class="max-w-[4.5rem]"
          viewBox="0 0 375 428"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M254.509 253.872L226.509 226.872"
            class="stroke-gray-400"
            stroke="currentColor"
            stroke-width="7"
            stroke-linecap="round" />
          <path
            d="M237.219 54.3721C254.387 76.4666 264.609 104.226 264.609 134.372C264.609 206.445 206.182 264.872 134.109 264.872C62.0355 264.872 3.60864 206.445 3.60864 134.372C3.60864 62.2989 62.0355 3.87207 134.109 3.87207C160.463 3.87207 184.993 11.6844 205.509 25.1196"
            class="stroke-gray-400"
            stroke="currentColor"
            stroke-width="7"
            stroke-linecap="round" />
          <rect
            x="270.524"
            y="221.872"
            width="137.404"
            height="73.2425"
            rx="36.6212"
            transform="rotate(40.8596 270.524 221.872)"
            class="fill-gray-400"
            fill="currentColor" />
          <ellipse
            cx="133.109"
            cy="404.372"
            rx="121.5"
            ry="23.5"
            class="fill-gray-400"
            fill="currentColor" />
          <path
            d="M111.608 188.872C120.959 177.043 141.18 171.616 156.608 188.872"
            class="stroke-gray-400"
            stroke="currentColor"
            stroke-width="7"
            stroke-linecap="round" />
          <ellipse
            cx="96.6084"
            cy="116.872"
            rx="9"
            ry="12"
            class="fill-gray-400"
            fill="currentColor" />
          <ellipse
            cx="172.608"
            cy="117.872"
            rx="9"
            ry="12"
            class="fill-gray-400"
            fill="currentColor" />
          <path
            d="M194.339 147.588C189.547 148.866 189.114 142.999 189.728 138.038C189.918 136.501 191.738 135.958 192.749 137.131C196.12 141.047 199.165 146.301 194.339 147.588Z"
            class="fill-gray-400"
            fill="currentColor" />
        </svg>
        <p class="mt-8 text-center text-sm text-gray-500">No tienes notificaciones</p>
      </div>
    </div>
  </ng-template>
</ng-container>
