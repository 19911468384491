<div (tuiActiveZoneChange)="toggleMenu($event)">
  <button class="h-6 w-6" (click)="toggleMenu(true)" type="button" id="mainMenuMobile">
    <img src="assets/images/icons/menu/bars.svg" alt="menú" />
  </button>

  <tui-elastic-container>
    <div *tuiSidebar="sidebarIsOpen() || false">
      <div class="h-screen overflow-y-scroll">
        <img
          src="assets/images/logo/dark.png"
          class="sticky top-0 w-full bg-white px-[23%] pb-2.5"
          alt="Tribuu Logo" />
        <!-- Sidebar menu mobile -->
        <ul class="mt-0 grid grid-cols-1 gap-2 px-4">
          <ng-container *ngFor="let option of menuMobile">
            <ng-container *ngIf="!option.auth || (option.auth && isAuth)">
              <li *ngIf="option.children">
                <ng-container
                  *ngTemplateOutlet="withChildren; context: { $implicit: option }"></ng-container>
              </li>
              <li *ngIf="!option.children">
                <ng-container
                  *ngTemplateOutlet="noChildren; context: { $implicit: option }"></ng-container>
              </li>
              <li *ngIf="option.external">
                <ng-container
                  *ngTemplateOutlet="external; context: { $implicit: option }"></ng-container>
              </li>
            </ng-container>
          </ng-container>
          <button
            class="mt-3 w-full md:hidden"
            tribuuButton
            theme="dark"
            routerLink="/login"
            *ngIf="!isAuth">
            <span class="capi">Inicia sesión</span>
          </button>
          <button
            (click)="openDialogRegister()"
            class="w-full md:hidden"
            tribuuButton
            theme="dark"
            *ngIf="!isAuth">
            <span class="capi">Registrarse</span>
          </button>

          <ng-container *ngIf="!!profile">
            <li class="md:hidden">
              <button
                class="flex w-full items-center justify-between gap-2 rounded border-2 border-gray-400 px-3 py-2 text-xs text-gray-700 md:px-3 md:py-2">
                <div class="inline-flex gap-2">
                  <img
                    class="h-5 w-5 rounded-md object-cover"
                    [src]="profile.imageUrl"
                    referrerpolicy="no-referrer"
                    alt="avatar"
                    title="mi foto de perfil" />
                  <span>{{ profile.name ?? profile.email | titlecase }}</span>
                </div>
                <img
                  class="h-5 w-5"
                  src="assets/images/icons/menu/chevron-down.svg"
                  alt="chevron" />
              </button>
              <div class="grid grid-cols-1 divide-y">
                <app-menu-user-auth-opts></app-menu-user-auth-opts>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </tui-elastic-container>
</div>

<ng-template #withChildren let-option>
  <button
    (click)="option.expanded = !option.expanded"
    class="flex w-full cursor-pointer items-center justify-between gap-2 text-xs text-gray-700">
    <div
      class="inline-flex w-full items-center rounded border border-gray-700 px-3 py-2 lg:border-0">
      <div class="flex grow items-center gap-1.5">
        <img class="h-5 w-5" *ngIf="option.icon" [src]="option.icon" alt="option icon" />
        <span class="font-medium">{{ option.title }}</span>
      </div>
      <img
        *ngIf="option.children && option.children.length"
        [ngClass]="{ 'rotate-0': option.expanded }"
        class="h-5 w-5 flex-none -rotate-180 transition-transform duration-200 ease-in-out"
        src="assets/images/icons/menu/chevron-down.svg"
        alt="chevron" />
    </div>
  </button>
  <tui-expand [expanded]="option.expanded">
    <div class="ml-4 flex flex-col" *ngFor="let item of option.children">
      <div class="mt-1.5">
        <ng-container *ngTemplateOutlet="noChildren; context: { $implicit: item }"></ng-container>
      </div>
    </div>
  </tui-expand>
</ng-template>
<ng-template #noChildren let-option>
  <button
    (click)="goto(option)"
    class="flex w-full cursor-pointer items-center justify-between gap-2 text-xs text-gray-700">
    <div
      class="inline-flex w-full items-center rounded border border-gray-700 px-3 py-2 lg:border-0">
      <div class="flex grow items-center gap-1.5">
        <img class="h-5 w-5" *ngIf="option.icon" [src]="option.icon" alt="option icon" />
        <span class="font-medium">{{ option.title }}</span>
      </div>
    </div>
  </button>
</ng-template>

<ng-template #external let-option>
  <a
    [href]="option.route"
    target="_blank"
    class="flex w-full cursor-pointer items-center justify-between gap-2 text-xs text-gray-700">
    <div
      class="inline-flex w-full items-center rounded border border-gray-700 px-3 py-2 lg:border-0">
      <div class="flex grow items-center gap-1.5">
        <img class="h-5 w-5" *ngIf="option.icon" [src]="option.icon" alt="option icon" />
        <span class="font-medium">{{ option.title }}</span>
      </div>
    </div>
  </a>
</ng-template>
