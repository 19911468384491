import { INTERNAL_ROUTES } from '../routes';
const myFunction = () => {};
type fnVoid = typeof myFunction;
export type itemMenu = {
  title: string;
  icon?: string;
  image?: string;
  external?: boolean;
  auth?: boolean;
  triber?: boolean;
  hide?: boolean;
  expanded?: boolean;
  id: string;
} & (
  | { route: string | fnVoid; children?: never; expanded?: never }
  | { children: itemMenu[]; route?: never; expanded: boolean }
);

export type Menu = itemMenu[];

export const mainMenuMap = {
  profile: {
    id: 'profile',
    title: 'Publicar mi perfil',
    icon: 'assets/images/icons/menu/service.svg',
    expanded: false,
    children: [
      {
        id: 'profile-child-0',
        title: 'Bolsa de trabajo',
        route: INTERNAL_ROUTES.APP_LANDINGS_RESUME,
      },
      {
        id: 'profile-child-1',
        title: 'Profesional independiente',
        route: INTERNAL_ROUTES.APP_LANDINGS_FREELANCER,
      },
    ],
  },
  hire: {
    id: 'hire',
    title: 'Contratar talento',
    icon: 'assets/images/icons/menu/add-user.svg',
    expanded: false,
    children: [
      {
        id: 'hire-child-0',
        title: 'Bolsa de trabajo',
        route: INTERNAL_ROUTES.APP_LANDINGS_HIRE_RESUME,
      },
      {
        id: 'hire-child-1',
        title: 'Profesional independiente',
        route: INTERNAL_ROUTES.APP_LANDINGS_HIRE_FRELANCE,
      },
    ],
  },
  pricing: {
    id: 'pricing',
    title: 'Precios',
    icon: 'assets/images/icons/menu/money.svg',
    route: INTERNAL_ROUTES.APP_PRICES_PAGE,
  },
  sessions: {
    id: 'main-menu-session',
    title: 'Sesiones',
    icon: 'assets/images/icons/menu/session.svg',
    auth: true,
    expanded: false,
    children: [
      {
        id: 'main-menu-session-child-0',
        title: 'Compradas',
        route: INTERNAL_ROUTES.APP_ACCOUNT_RESERVATIONS,
      },
      {
        id: 'main-menu-session-child-1',
        title: 'Triber',
        route: INTERNAL_ROUTES.APP_ACCOUNT_SESSIONS,
        auth: true,
        triber: true,
      },
    ],
  },
};

export const mainMenu: Menu = Object.values(mainMenuMap);
