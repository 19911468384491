import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { SidebarActions } from '@core/store/sidebar/sidebar.actions';
import { SidebarState } from '@core/store/sidebar/sidebar.state';
import { itemMenu, mainMenu } from '@data/constants/config';
import { Category, User } from '@api';
import { Select, Store } from '@ngxs/store';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { RegisterUserComponent } from 'app/ui/dialogs/register-user/register-user.component';
@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuMobileComponent {
  private readonly store = inject(Store);
  @Output() navigate = new EventEmitter<itemMenu>();
  @Input() profile!: User | null;
  sidebarIsOpen = this.store.selectSignal(SidebarState.open);
  router = inject(Router);
  menuMobile = mainMenu;

  constructor(@Inject(TuiDialogService) private readonly dialogs: TuiDialogService) {}

  public toggleMenu(value: boolean): void {
    const action = value ? new SidebarActions.Open() : new SidebarActions.Close();
    this.store.dispatch(action);
  }

  get isAuth(): boolean {
    return this.profile !== null;
  }

  openDialogRegister() {
    this.dialogs.open<number>(new PolymorpheusComponent(RegisterUserComponent)).subscribe();
  }

  goto(menuOption: itemMenu) {
    if (!menuOption.route) return;

    if (typeof menuOption.route === 'string') {
      if (menuOption.external) {
        window.open(menuOption.route, '_blank');
        return;
      }
      if (menuOption.route) {
        this.navigate.emit(menuOption);
        this.store.dispatch(new SidebarActions.Close());
      }
    } else {
      menuOption.route();
    }
  }
}
