<figure class="fixed top-0 z-[60] h-[100vh] w-full bg-[rgba(0,0,0,0.8)]">
  <figcaption
    class="relative top-32 mx-auto max-w-2xl bg-white px-7 pt-7 pb-24 max-md:top-0 max-md:min-h-[100vh] max-md:px-2 max-md:pb-12">
    <img
      src="assets/images/home/trazo-close.png"
      alt="close"
      class="absolute top-7 right-7 cursor-pointer max-md:top-2 max-md:right-2"
      (click)="closeModal()" />

    <ng-content />

    <img
      src="assets/images/home/trazo-footer.png"
      alt="footer"
      class="absolute bottom-0 right-0 w-full" />
  </figcaption>
</figure>
