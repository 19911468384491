import { inject, Injectable } from '@angular/core';
import { AuthState } from '@core/store/auth/auth.state';
import { ProfileActions } from '@core/store/profile/profile.actions';
import { ProfileState } from '@core/store/profile/profile.state';
import { LANDING_TOUR, LANDING_TOUR_MOBILE } from '@data/constants/tours/landings.tour.config';
import { Store } from '@ngxs/store';
import { AuthLoginComponent } from '@shared/components/templates';
import { isMobile } from '@shared/utils';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { ShepherdService } from 'angular-shepherd';
import { filter, firstValueFrom, fromEvent, Subscription, switchMap, tap } from 'rxjs';
export const BASE_PATH_CONTENT = '/assets/html/steps-tour/';

@Injectable({ providedIn: 'root' })
export class AppTourService extends ShepherdService {
  private readonly store = inject(Store);
  private readonly dialogs = inject(TuiDialogService);
  private tourEvents$ = fromEvent<CustomEvent>(window, 'tourEvent').pipe(
    tap(event => this._handleTourEvents(event.detail))
  );
  eventsSubscription: Subscription | undefined;
  constructor() {
    super();
    //// add default options
    this.confirmCancel = true;
    this.confirmCancelMessage = '¿Estás seguro que deseas salir?';
    this.defaultStepOptions = {
      canClickTarget: false,
      cancelIcon: { enabled: true, label: 'Salir' },
      modalOverlayOpeningPadding: 6,
    };
    this.exitOnEsc = false;
    this.keyboardNavigation = false;
    this.modal = true;
    this.onTourFinish = () => {
      this.store.dispatch(new ProfileActions.SetOmitMetadaLandingsTutorial());
      if (this.eventsSubscription) this.eventsSubscription.unsubscribe();
    };
  }

  private _handleTourEvents(eventDetail: string) {
    switch (eventDetail) {
      case 'prev':
        this.back();
        break;
      case 'next':
        this.next();
        break;
      case 'back':
        this.back();
        break;
      case 'register':
        this.complete();
        this._register();
        break;
    }
  }

  //override start
  start() {
    const isAuth = this.store.selectSnapshot(AuthState.isAuthenticated);
    this.addSteps(isMobile() ? LANDING_TOUR_MOBILE(isAuth) : LANDING_TOUR(isAuth)); //// add steps to the tour
    this.eventsSubscription = this.tourEvents$.subscribe();

    if (!isAuth) return super.start();

    firstValueFrom(
      this.store.select(ProfileState.isProfileLoaded).pipe(
        filter(Boolean),
        switchMap(() => this.store.select(ProfileState.metadata)),
        tap(metadata => {
          if (metadata.isLandingsTutorialOmited || false) return;
          super.start();
        })
      )
    );
  }

  private _register() {
    this.complete();
    this.dialogs
      .open<boolean>(new PolymorpheusComponent(AuthLoginComponent), {
        closeable: true,
        size: isMobile() ? 'fullscreen' : 'm',
        dismissible: true,
      })
      .subscribe();
  }
  //
}
