<app-wizard-onboarding>
  <p class="text-center text-3xl font-bold">¿Qué te gustaría realizar?</p>

  <img src="assets/images/home/trazo.svg" alt="trazo" class="mx-auto mt-7 h-[35px]" />

  <p class="mt-7 text-center leading-tight">Selecciona una opción:</p>

  <div class="mt-7 flex w-full justify-center gap-5">
    <figure
      class="w-1/3 cursor-pointer rounded-xl border-2 border-primary p-5 max-md:px-2 max-sm:w-5/12"
      [routerLink]="talentPublishUrl">
      <div class="max-h-[105px] w-full">
        <img
          src="assets/images/home/wizard-hire-talent-one.png"
          alt="opt icon"
          class="mx-auto object-contain" />
      </div>
      <figcaption class="mt-5 w-full text-center text-lg font-bold leading-[18px] text-primary">
        Publicar
        <br />
        una vacante
      </figcaption>
    </figure>

    <figure
      class="w-1/3 cursor-pointer rounded-xl border-2 border-primary p-5 max-md:px-2 max-sm:w-5/12"
      (click)="searchClick()">
      <div class="max-h-[105px] w-full">
        <img
          src="assets/images/home/wizard-hire-talent-two.png"
          alt="opt icon"
          class="mx-auto object-contain" />
      </div>
      <figcaption class="mt-5 w-full text-center text-lg font-bold leading-[18px] text-primary">
        Buscar en el
        <br />
        directorio
      </figcaption>
    </figure>
  </div>

  <div class="mt-7 flex w-full justify-center" *ngIf="hasReturnButton">
    <app-button-tribuu
      title="Regresar"
      theme="dark"
      optionalClass="w-full"
      class="w-1/3"
      [routerLink]="wizardUrl" />
  </div>
</app-wizard-onboarding>
