import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@core/store/auth/auth.state';
import { EMPTY_STRING } from '@data/constants/routes';
import { Store } from '@ngxs/store';
import { filter, map, switchMap, tap } from 'rxjs';
export const NoAuthGuard = () => {
  const store = inject(Store);
  const route = inject(Router);

  return store.select(AuthState.isLoaded).pipe(
    filter(Boolean),
    switchMap(() => store.select(AuthState.isAuthenticated)),
    map(isAuth => {
      if (isAuth) return route.createUrlTree([EMPTY_STRING]);
      return true;
    })
  );
};
