import { inject, isDevMode, NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import {
  EMPTY_STRING,
  INTERNAL_PATHS,
  INTERNAL_ROUTES,
  LOGIN,
  ROUTES_PATHS,
} from '@data/constants/routes';
import { SkeletonComponent } from '@layout/skeleton/skeleton.component';
import { renderStaticHtmlGuard } from '@modules/static-content/guards/static-content.guard';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { LinkedinAuthHandlerGuard } from '@core/security/guards/linkedin-auth-handler.guard';
import { NoAuthGuard } from '@core/security/guards/no-auth.guard';
import posthog from 'posthog-js';
import { WizardHireTalentComponent } from './ui/dialogs/wizard-content/wizard-hire-talent/wizard-hire-talent.component';
import { WizardRoleTypeComponent } from './ui/dialogs/wizard-content/wizard-role-type/wizard-role-type.component';
import { WizardTalentPublishComponent } from './ui/dialogs/wizard-content/wizard-talent-publish/wizard-talent-publish.component';
import { WizardWelcomeComponent } from './ui/dialogs/wizard-content/wizard-welcome/wizard-welcome.component';
import { AppTourService } from '@core/services/internals/tour.service';
import { filter } from 'rxjs';
import { environment } from '@env/environment';

const routes: Routes = [
  {
    path: INTERNAL_PATHS.APP_VIDEOCALL_DEFAULT /** Meeting Room */,
    loadChildren: () => import('@modules/videocall/videocall.module').then(m => m.VideocallModule),
    canActivate: [AuthGuard],
  },
  {
    path: LOGIN, // Auth Handler
    component: SkeletonComponent,
    canActivate: [NoAuthGuard, AuthGuard],
  },
  {
    path: 'auth/linkedin/callback', // Auth Handler Linkedin
    component: SkeletonComponent,
    canActivate: [NoAuthGuard, LinkedinAuthHandlerGuard],
  },
  {
    path: EMPTY_STRING,
    component: SkeletonComponent,
    children: [
      { path: INTERNAL_ROUTES.APP_WIZARD_WELCOME, component: WizardWelcomeComponent },
      { path: INTERNAL_ROUTES.APP_WIZARD_HIRE_TALENT, component: WizardHireTalentComponent },
      { path: INTERNAL_ROUTES.APP_WIZARD_TALENT_TYPE, component: WizardRoleTypeComponent },
      { path: INTERNAL_ROUTES.APP_WIZARD_TALENT_PUBLISH, component: WizardTalentPublishComponent },
      {
        path: INTERNAL_PATHS.APP_DEFAULT /** Main Page */,
        loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule),
        data: { preload: true },
      },
      {
        path: INTERNAL_PATHS.APP_CATEGORY_DEFAULT /** Categories */,
        loadChildren: () =>
          import('@modules/categories/categories.module').then(m => m.CategoriesModule),
        data: { preload: false },
      },
      {
        path: INTERNAL_PATHS.APP_PUBLICATION_DEFAULT /** Profesional independiente */,
        loadChildren: () =>
          import('@modules/publications/publications.module').then(m => m.PublicationsModule),
      },
      {
        path: ROUTES_PATHS.APP.SESSION.DEFAULT /** Sessions */,
        loadChildren: () => import('@modules/sessions/sessions.module').then(m => m.SessionsModule),
      },
      {
        path: INTERNAL_PATHS.APP_RESERVATION_DEFAULT /** Reservations */,
        loadChildren: () =>
          import('@modules/reservations/reservations.module').then(m => m.ReservationsModule),
      },

      {
        path: INTERNAL_PATHS.APP_INCOVENIENT_DEFAULT /** reports */,
        loadChildren: () =>
          import('@modules/inconvenient/inconvenient.module').then(m => m.InconvenientModule),
        data: { preload: false },
      },
      {
        path: INTERNAL_PATHS.APP_ACCOUNT_DEFAULT /** User Account */,
        loadChildren: () => import('@modules/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
      },
      {
        path: INTERNAL_PATHS.APP_WORK_OFFERS_DEFAULT /** Job Offers */,
        loadChildren: () =>
          import('@modules/work-offers/work-offers.module').then(m => m.WorkOffersModule),
      },
      {
        path: INTERNAL_PATHS.APP_JOB_DEFAULT /** Duplicated Job Offers */,
        loadChildren: () => import('@modules/jobs/jobs.module').then(m => m.JobsModule),
        canActivate: [AuthGuard],
        data: { preload: false },
      },
      {
        path: INTERNAL_PATHS.APP_COMPANIES_DEFAULT /** Companies */,
        loadChildren: () =>
          import('@modules/companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [AuthGuard],
      },
      {
        path: INTERNAL_PATHS.APP_STATIC_DEFAULT /** Static html content */,
        canActivate: [renderStaticHtmlGuard],
        loadChildren: () =>
          import('./modules/static-content/static-content.module').then(m => m.StaticContentModule),
        data: { preload: false },
      },
      { path: '**', redirectTo: EMPTY_STRING, pathMatch: 'full' }, // 404
    ],
  },

  { path: '**', redirectTo: EMPTY_STRING, pathMatch: 'full' }, // 404
];
@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      // enableTracing: isDevMode(),
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
  private readonly tourService = inject(AppTourService);
  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: event => {
        posthog.capture('$pageview');
        this.handleLaunchTour(event.url);
      },
    });
  }

  handleLaunchTour(url: string) {
    const routes = [
      INTERNAL_ROUTES.APP_LANDINGS_FREELANCER,
      INTERNAL_ROUTES.APP_LANDINGS_HIRE_FRELANCE,
      INTERNAL_ROUTES.APP_LANDINGS_HIRE_RESUME,
      INTERNAL_ROUTES.APP_LANDINGS_RESUME,
    ];
    if (isDevMode() && routes.includes(url)) {
      this.tourService.start();
    }
  }
}
