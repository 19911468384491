<!-- *Todo - Agregar enlaces al footer luego de organizar rutas -->
<section>
  <div class="container mx-auto px-3 md:px-12">
    <div class="mx-auto mt-6 w-40 md:mt-10 md:w-56">
      <app-logo-tribuu></app-logo-tribuu>
    </div>

    <div class="py-5">
      <app-social-networks></app-social-networks>
    </div>
    <app-copyright></app-copyright>
  </div>
</section>

<div class="mt-6 border-t border-gray-400 bg-default lg:mt-10">
  <div class="container mx-auto max-w-4xl p-3 md:px-24 md:py-6">
    <div class="flex flex-wrap gap-y-3">
      <!-- <div class="w-1/2 md:w-1/3">
        <h3 class="text-gray pb-3 text-xl font-bold">Nosotros</h3>
        <ul class="text-gray leading-7">
          <li>¿Quienes somos?</li>
          <li>Nuestra Tribuu</li>
          <li>¿Cómo funciona?</li>
          <li>Mapa del sitio</li>
        </ul>
      </div> -->
      <div class="w-1/2 md:w-1/3 lg:text-left">
        <h3 class="text-gray pb-3 text-xl font-bold">Legal</h3>
        <ul class="text-gray leading-7">
          <li>
            <a routerLink="/info/terminos-y-condiciones">Términos y condiciones</a>
          </li>
          <li>
            <a routerLink="/info/aviso-de-privacidad">Aviso de privacidad</a>
          </li>
          <!-- <li>
            <a routerLink="/info/politicas">Políticas</a>
          </li> -->
        </ul>
      </div>
      <div class="w-1/2 md:w-1/3 lg:text-center">
        <h3 class="text-gray pb-3 text-xl font-bold">Comunidad</h3>
        <ul class="text-gray leading-7">
          <li><a routerLink="/info/factura">Facturación</a></li>
          <li>
            <a routerLink="/info/codigo-de-conducta">Código de conducta</a>
          </li>
        </ul>
      </div>
      <div class="w-1/2 md:w-1/3 lg:text-right">
        <h3 class="text-gray pb-3 text-xl font-bold">Ayuda</h3>
        <ul class="text-gray leading-7">
          <li>
            <a href="https://academy.tribuu.com" target="_blank">Tribuu Academy</a>
          </li>
          <li>
            <a href="mailto:hola@tribuu.com">hola&#64;tribuu.com</a>
          </li>
          <li>
            <a [routerLink]="QUESTIONS_ROUTE">Preguntas frecuentes</a>
          </li>
          <li>
            <div class="flex w-full flex-row items-center gap-3 lg:justify-end">
              <div class="flex flex-row gap-1">
                <svg-icon
                  class="h-[19.68px] w-[19.68px]"
                  src="assets/images/default/whatsapp.svg"></svg-icon>
                <svg-icon
                  class="h-[19.68px] w-[19.68px]"
                  src="assets/images/default/phone.svg"></svg-icon>
              </div>
              <p>+52 1 5569437052</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mx-auto w-40 py-10">
      <app-image
        src="https://storage.googleapis.com/tribuu-bucket/Assets/glifos/footer.png"
        alt="tribar-trazos"></app-image>
    </div>
  </div>
</div>
