import { Injectable, inject } from '@angular/core';
import { UserApp } from '@data/interfaces';
import { NotificationsApi, TribuNotification } from '@api';
import {
  Action,
  Actions,
  NgxsOnInit,
  Select,
  Selector,
  State,
  StateContext,
  ofActionSuccessful,
} from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { AuthActions } from '../auth/auth.actions';
import { AuthState } from '../auth/auth.state';
import { NotificationActions } from './notification.actions';

export interface NotificationStateModel {
  notifications: TribuNotification[];
  loading: boolean;
  loaded: boolean;
}

@State<NotificationStateModel>({
  name: 'notifications',
  defaults: {
    notifications: [],
    loading: false,
    loaded: false,
  },
})
@Injectable()
export class NotificationState implements NgxsOnInit {
  @Select(AuthState.userAuth) user$!: Observable<UserApp>;
  private readonly actions = inject(Actions);
  private notificationsApi = inject(NotificationsApi);
  @Selector()
  static state(state: NotificationStateModel) {
    return state;
  }
  @Selector() // selector para obtener las notificaciones
  static notifications(state: NotificationStateModel) {
    return state.notifications;
  }
  @Selector() static notificationsReaded(state: NotificationStateModel) {
    return state.notifications.filter(notification => notification.read);
  }
  @Selector() static notificationsUnread(state: NotificationStateModel) {
    return state.notifications.filter(notification => !notification.read);
  }
  @Selector() // selector para contar las notificaciones no leidas
  static unreadCount(state: NotificationStateModel) {
    return state.notifications.filter(notification => !notification.read).length;
  }

  // hooks
  ngxsOnInit(ctx: StateContext<NotificationStateModel>): void {
    const login$ = this.actions.pipe(ofActionSuccessful(AuthActions.SignInSuccess));
    login$.subscribe(() => ctx.dispatch(new NotificationActions.FetchAll()));
  }

  @Action(NotificationActions.Add)
  addNotification(
    { patchState, getState }: StateContext<NotificationStateModel>,
    { payload }: NotificationActions.Add
  ) {
    const state = getState();
    patchState({ notifications: [payload, ...state.notifications] });
  }

  @Action(NotificationActions.Remove)
  removeNotification(
    ctx: StateContext<NotificationStateModel>,
    { uid }: NotificationActions.Remove
  ) {
    this.removeNotificationState(ctx, uid);
    return this.notificationsApi.remove({ uid });
  }
  removeNotificationState(ctx: StateContext<NotificationStateModel>, uid: string) {
    const state = ctx.getState();
    const notifications = state.notifications.filter(notification => notification.uid !== uid);
    ctx.patchState({ notifications });
  }

  @Action(NotificationActions.RemoveAll)
  removeAllNotifications({ patchState }: StateContext<NotificationStateModel>) {
    patchState({ notifications: [], loaded: false });
  }

  @Action(NotificationActions.ReadAll)
  readAllNotifications(ctx: StateContext<NotificationStateModel>) {
    const state = ctx.getState();
    const notifications = state.notifications.map(notification => {
      return { ...notification, read: true };
    });
    ctx.patchState({ notifications });
    return this.notificationsApi.readAll();
  }

  @Action(NotificationActions.FetchAll)
  fetchNotifications({ patchState }: StateContext<NotificationStateModel>) {
    patchState({ loading: true });
    return this.notificationsApi.findAllFromUserAuth({}).pipe(
      tap(notifications => {
        patchState({ notifications, loading: false, loaded: true });
      })
    );
  }

  @Action(NotificationActions.Reset)
  resetNotifications({ patchState }: StateContext<NotificationStateModel>) {
    patchState({ notifications: [], loaded: false, loading: false });
  }
}
