<ng-container *ngIf="userAuth$ | async as user">
  <div
    class="fixed bottom-0 z-50 flex w-screen flex-nowrap transition-all duration-500 md:bottom-2 md:right-2 md:w-fit">
    <ng-container *ngIf="conversations$ | async as chats">
      <div
        class="flex w-full flex-col border bg-white shadow-md md:w-[20rem]"
        *ngFor="let chat of chats"
        [@slideInOut]>
        <div class="bg-secondary flex items-center justify-between border-b p-2">
          <div class="flex items-center">
            <img
              class="h-10 w-10 rounded-full object-cover"
              src="{{ fromUserImage(chat) }}"
              alt="img triber" />
            <div class="pl-2">
              <div class="font-semibold text-white">
                <a class="hover:underline">{{ fromUserTitle(chat) }}</a>
              </div>
              <div class="text-xs text-white">{{ true ? 'en linea' : 'desconectado' }}</div>
            </div>
          </div>
          <div>
            <button
              nz-tooltip
              [nzTooltipTitle]="chat.isOpened ? 'Minimizar' : 'Maximizar'"
              [nzTooltipMouseEnterDelay]="1"
              nzTooltipOverlayClassName="text-xs"
              (click)="toggleChat(chat.uid)"
              class="inline-flex rounded-full p-2 text-white transition-all duration-300 hover:bg-indigo-50 hover:text-gray-800"
              type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="h-6 w-6">
                <path
                  *ngIf="!chat.isOpened"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                <path *ngIf="chat.isOpened" stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
              </svg>
            </button>

            <button
              nz-tooltip
              nzTooltipOverlayClassName="text-xs"
              nzTooltipTitle="Cerrar"
              [nzTooltipMouseEnterDelay]="1"
              (click)="closeChat(chat.uid)"
              class="inline-flex rounded-full p-2 text-white transition-all duration-300 hover:bg-indigo-50 hover:text-gray-800"
              type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div [ngClass]="{ 'pb-3': !chat.isOpened }">
          <div *ngIf="chat.isOpened" [@tuiHeightCollapse]="speed">
            <div class="flex h-[20rem] flex-col-reverse gap-1.5 overflow-y-auto px-4 py-4">
              <ng-container *ngIf="chat.messages?.length; else emptyMessages">
                <ng-container *ngFor="let msg of chat.messages; index as i; trackBy: trackByMessages">
                  <div
                    class="slide-in-bottom ml-12 flex flex-row-reverse items-start"
                    *ngIf="msg.fromUser?.uid === user.uid; else IDontSentThat">
                    <div class="bg-secondary relative my-1 rounded-b-lg rounded-tl-lg p-2 text-white">
                      <!-- normal text -->
                      <ng-container *ngIf="!msg.file">
                        <div class="text-right text-sm">{{ msg.content }}</div>
                      </ng-container>
                      <!-- file viewer -->
                      <ng-container *ngIf="msg.file">
                        <div [ngSwitch]="fileType(msg.content)">
                          <div *ngSwitchCase="typesFiles.image">
                            <img nz-image class="w-full" [nzSrc]="msg.file" alt="preview" />
                          </div>
                          <div *ngSwitchCase="typesFiles.pdf">
                            <a [download]="msg.file">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-10 w-10"
                                viewBox="0 0 24 24"
                                style="fill: rgba(0, 0, 0, 1)">
                                <path
                                  d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z"></path>
                                <path
                                  d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </ng-container>

                      <div class="mt-1 text-right text-[10px] leading-none text-gray-300">
                        {{ msg.sentAt | date : 'short' }}
                      </div>
                    </div>
                  </div>

                  <ng-template #IDontSentThat>
                    <div class="slide-in-bottom flex items-start">
                      <div class="mr-2 flex flex-none flex-col items-center space-y-1">
                        <img
                          class="h-10 w-10 rounded-full object-cover"
                          src="{{ fromUserImage(chat) }}"
                          alt="user img" />
                        <div class="overflow-elipsis block w-10 truncate text-xs hover:underline">
                          {{ msg.fromUser?.name }}
                        </div>
                      </div>
                      <div class="bg-default relative my-2 rounded-b-lg rounded-tr-lg p-2">
                        <div class="text-sm">{{ msg.content }}</div>
                        <div class="mt-1 text-left text-[10px] leading-none text-gray-500">
                          {{ msg.sentAt | date : 'short' }}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>

              <!-- Empty state messages -->
              <ng-template #emptyMessages>
                <div
                  class="flex h-full w-full flex-col items-center justify-center"
                  *ngIf="!chat.loadingMessages">
                  <span class="text-3xl">👋</span>
                  <div class="text-center text-gray-500">
                    ¿Tienes alguna duda? <br />
                    Estoy aquí para ayudarte
                  </div>
                </div>
              </ng-template>
            </div>
            <form autocomplete="off">
              <div class="flex items-center gap-2 rounded-lg bg-gray-50 px-2 py-2">
                <!-- <nz-upload
                  [nzDisabled]="chat.isSending || chat.loadingMessages"
                  [nzAction]="httpUploadFiles"
                  [nzSize]="8000"
                  [nzShowUploadList]="false"
                  (nzChange)="handleChange($event, chat.uid)">
                  <button
                    type="button"
                    class="inline-flex cursor-pointer justify-center rounded-full p-1 text-gray-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-5 w-5">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                    </svg>
                    <span class="sr-only">Send File</span>
                  </button>
                </nz-upload> -->

                <input
                  [disabled]="chat.isSending"
                  class="mx-0 block w-full border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:outline-none"
                  type="text"
                  autocomplete="off"
                  #message
                  maxlength="255"
                  name="message"
                  (keydown.enter)="addMessage(chat.uid)"
                  placeholder="Escribe un mensaje..." />
                <tui-loader
                  class="flex-none"
                  [showLoader]="chat.isSending"
                  size="xs"
                  [inheritColor]="true"
                  [overlay]="true">
                  <button
                    type="button"
                    (click)="addMessage(chat.uid)"
                    class="inline-flex cursor-pointer justify-center rounded-full bg-gray-600 p-2 text-white hover:bg-blue-300">
                    <svg
                      aria-hidden="true"
                      class="h-6 w-6 rotate-90 p-0.5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                    </svg>
                    <span class="sr-only">Send message</span>
                  </button>
                </tui-loader>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
