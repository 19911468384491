import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { AuthState } from '@core/store/auth/auth.state';
import { ConversationsActions } from '@core/store/conversations/conversations.actions';
import { ReservationConversation } from '@core/store/conversations/conversations.models';
import { ConversationsState } from '@core/store/conversations/conversations.state';
import { UserApp } from '@data/interfaces';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { slideInOut } from '@shared/animations/slide-in-out.animation';
import { tuiPure } from '@taiga-ui/cdk';
import { TuiDurationOptions, TuiLoaderModule, tuiHeightCollapse } from '@taiga-ui/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadChangeParam, NzUploadModule } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
enum mimeTypes {
  image = 'image',
  video = 'video',
  pdf = 'pdf',
  other = 'other',
}
@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut, tuiHeightCollapse],
  standalone: true,
  imports: [
    NzToolTipModule,
    CommonModule,
    NzImageModule,
    NzUploadModule,
    NzDropDownModule,
    TuiLoaderModule,
  ],
})
export class ConversationsComponent {
  @ViewChild('message') message!: ElementRef;
  private store = inject(Store);
  httpUploadFiles = environment.apiUrl + '/messages/upload';
  typesFiles = mimeTypes;
  speed: TuiDurationOptions = { value: '', params: { duration: 600 } };
  @Select(ConversationsState.Conversations) conversations$!: Observable<ReservationConversation[]>;
  @Select(AuthState.userAuth) userAuth$!: Observable<UserApp | null>;
  // private readonly destroy$: TuiDestroyService // @Inject(TuiDestroyService) // @Self() // private realTime: AngularFireDatabase,
  addMessage(reservationId: string) {
    const content = this.message.nativeElement.value;
    if (!content) {
      return;
    }
    this.message.nativeElement.value = '';
    return this.store.dispatch(new ConversationsActions.SendMessage({ content, reservationId }));
  }

  @tuiPure
  fromUserTitle(chat: ReservationConversation) {
    return chat.fromUser ? chat.fromUser.name : chat.triber.name;
  }

  @tuiPure
  fromUserImage(chat: ReservationConversation) {
    return chat.fromUser ? chat.fromUser.imageUrl : chat.triber.imageUrl;
  }

  toggleChat(uid: string) {
    this.store.dispatch(new ConversationsActions.ToogleConversation(uid));
  }

  closeChat(uid: string) {
    this.store.dispatch(new ConversationsActions.CloseConversation(uid));
  }

  trackByMessages(item: any) {
    return item.date;
  }

  // This function takes a mime type and returns a mimeTypes enum
  // It determines the mime type by searching for the word "image", "video", or "pdf".
  // If the mime type is none of those, it returns the "other" mime type.
  fileType(mime: string): mimeTypes {
    if (mime.includes('image')) {
      return mimeTypes.image;
    } else if (mime.includes('video')) {
      return mimeTypes.video;
    } else if (mime.includes('pdf')) {
      return mimeTypes.pdf;
    } else {
      return mimeTypes.other;
    }
  }

  handleChange(info: NzUploadChangeParam, chatId: string) {
    // if (info.file.status === 'done') {
    //   const { url, mimetype } = info.file.response as File;
    //   this.store.dispatch(
    //     new ReservationActions.SendMessage({
    //       reservationId: chatId,
    //       content: mimetype,
    //       file: url,
    //     })
    //   );
    // } else if (info.file.status === 'error') {
    // }
  }
}
