import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  isDevMode,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TuiAlertModule, TuiDialogModule, TuiExpandModule, TuiRootModule } from '@taiga-ui/core';
import { environment } from 'environments/environment';
import { AppRoutingModule } from './app-routing.module';
/** Firebase */
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { HttpErrorsInterceptor } from '@core/security/interceptors/http-errors.interceptor';
import { DataModule } from '@data/data.module';
import { ConversationsComponent } from '@layout/conversations/conversations.component';
import { FavoritesDropdownComponent } from '@layout/header/components/favorites-dropdown/favorites-dropdown.component';
import { FormVerifyPhoneComponent } from '@modules/account/components/templates/form-verify-phone/form-verify-phone.component';
import { CopyrightComponent } from '@modules/home/components/atoms/copyright/copyright.component';
import { SocialIconComponent } from '@modules/home/components/atoms/social-icon/social-icon.component';
import { SocialNetworksComponent } from '@modules/home/components/molecules/social-networks/social-networks.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { RxLet } from '@rx-angular/template/let';
import { LogoTribuuComponent } from '@shared/components/atoms';
import { SearchServiceComponent } from '@shared/components/molecules';
import { TribuuButtonDirective } from '@shared/directives/tribuu-button.directive';
import { AddComissionPipe } from '@shared/pipes/add-comission.pipe';
import { AddIvaPipe } from '@shared/pipes/add-iva.pipe';
import { DateAgoPipe } from '@shared/pipes/date-ago.pipe';
import { MoneyPipe } from '@shared/pipes/money.pipe';
import { HtmlTrustPipe } from '@shared/pipes/pipes.module';
import { RoundPipe } from '@shared/pipes/round.pipe';
import { POSTHOG, STRIPE_KEY } from '@shared/utils/variables';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { TuiDropdownModule } from '@taiga-ui/core';
import { TUI_LANGUAGE, TUI_SPANISH_LANGUAGE } from '@taiga-ui/i18n';
import { TuiBadgedContentModule, TuiBadgeModule, TuiElasticContainerModule } from '@taiga-ui/kit';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NgxStripeModule } from 'ngx-stripe';
import posthog from 'posthog-js';
import { of } from 'rxjs';
import { AppComponent } from './app.component';
import { initializeApp } from './app.initializer';
import { appState } from './app.state';
import { CoreModule } from './core/core.module';
import * as layout from './layout';
import { DropdownMenuComponent } from './layout/header/components/dropdown-menu/dropdown-menu.component';
registerLocaleData(es);
@NgModule({
  declarations: [
    AppComponent,
    ...layout.components,
    DropdownMenuComponent,
    FavoritesDropdownComponent,
    CopyrightComponent,
    SocialNetworksComponent,
    SocialIconComponent,
  ],

  imports: [
    BrowserModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    BrowserAnimationsModule,
    NgxsModule.forRoot(appState, { developmentMode: isDevMode() }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    NgxsLoggerPluginModule.forRoot({ disabled: true }),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'tribuu', disabled: environment.production }),
    TuiRootModule,
    AngularSvgIconModule.forRoot(),
    DataModule,
    CoreModule,
    AppRoutingModule,
    NzDropDownModule,
    TuiActiveZoneModule,
    TuiSidebarModule,
    SearchServiceComponent,
    DateAgoPipe,
    TribuuButtonDirective,
    TuiDialogModule,
    RxLet,
    TuiAlertModule,
    TuiBadgeModule,
    TuiBadgedContentModule,
    TuiExpandModule,
    TuiDropdownModule,
    MoneyPipe,
    AddComissionPipe,
    AddIvaPipe,
    RoundPipe,
    LogoTribuuComponent,
    TuiPreviewModule,
    TuiElasticContainerModule,
    HtmlTrustPipe,
    FormVerifyPhoneComponent,
    ConversationsComponent,
  ],

  providers: [
    { provide: STRIPE_KEY, useValue: environment.stripeKey },
    { provide: POSTHOG, useValue: posthog.init(environment.posthogConfig.apiKey) },
    { provide: PERSISTENCE, useValue: 'local' },
    { provide: TUI_LANGUAGE, useValue: of(TUI_SPANISH_LANGUAGE) },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () =>
        posthog.init(environment.posthogConfig.apiKey, {
          api_host: environment.posthogConfig.host,
        }),
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Store],
      multi: true,
    },
    // EMULATORS FIREBASE
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined,
    // },
    // {
    //   provide: USE_DATABASE_EMULATOR,
    //   useValue: environment.useEmulators ? ['http://localhost', 9000] : undefined,
    // },
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: environment.useEmulators ? ['http://localhost', 8080] : undefined,
    // },
    // {
    //   provide: USE_STORAGE_EMULATOR,
    //   useValue: environment.useEmulators ? ['http://localhost', 9199] : undefined,
    // },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
