import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthState } from '@core/store/auth/auth.state';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Configuration, CoreApiModule } from '@api';
import { CustomHttpParameterCodec } from './services/api/encoder';

@NgModule({
  declarations: [],
  imports: [CoreApiModule],
  providers: [
    {
      provide: Configuration,
      useFactory: (store: Store) =>
        new Configuration({
          basePath: environment.apiUrl,
          accessToken: () =>
            store.selectSnapshot<string | null>(AuthState.token) || 'Not Authenticated',
          encoder: new CustomHttpParameterCodec(),
        }),
      deps: [Store],
      multi: false,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class DataModule {}
