import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@api';
import { AuthState } from '@core/store/auth/auth.state';
import { ProfileState } from '@core/store/profile/profile.state';
import { SidebarActions } from '@core/store/sidebar/sidebar.actions';
import { TriberState } from '@core/store/triber/triber.state';
import { itemMenu, mainMenu } from '@data/constants/config';
import { INTERNAL_ROUTES, ROUTES_PATHS } from '@data/constants/routes';
import { Store } from '@ngxs/store';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { RegisterUserComponent } from 'app/ui/dialogs/register-user/register-user.component';
import { tap } from 'rxjs';

export interface IPersonalData {
  name: string;
  img: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  private store = inject(Store);
  private cd = inject(ChangeDetectorRef);
  private router = inject(Router);
  authLoaded = this.store.selectSignal(AuthState.isLoaded);
  profile!: User | null;
  isAuthenticated = this.store.selectSignal(AuthState.isAuthenticated);
  isTriber = false;
  menuOptions = mainMenu;
  personalData: null | IPersonalData = null;
  isRouteToSearchExclude = signal(false);

  constructor(@Inject(TuiDialogService) private readonly dialogs: TuiDialogService) {
    this.store
      .select(ProfileState.profile)
      .pipe(tap(() => this.cd.markForCheck()))
      .subscribe({ next: p => (this.profile = p) });

    this.store
      .select(TriberState.triber)
      .pipe(tap(() => this.cd.markForCheck()))
      .subscribe({
        next: t => {
          if (t) {
            this.personalData = { name: t.fullName, img: t.imageUrl };
          }
          this.isTriber = Boolean(t);
        },
      });

    this.router.events.subscribe(() => {
      const excludeRoutes = [
        ROUTES_PATHS.APP.HOME_PAGE,
        INTERNAL_ROUTES.APP_LANDINGS_RESUME,
        INTERNAL_ROUTES.APP_LANDINGS_FREELANCER,
        INTERNAL_ROUTES.APP_LANDINGS_HIRE_RESUME,
        INTERNAL_ROUTES.APP_LANDINGS_HIRE_FRELANCE,
      ];

      this.isRouteToSearchExclude.set(excludeRoutes.includes(this.router.url));
    });
  }

  navigateTo(menuItem: itemMenu) {
    if (menuItem.external) {
      if (typeof menuItem.route === 'string') window.open(menuItem.route, '_blank');
    } else if (menuItem.route) {
      if (typeof menuItem.route === 'string') {
        if (menuItem.route) {
          this.router.navigate([menuItem.route]);
          this.store.dispatch(new SidebarActions.Close());
        }
      } else {
        menuItem.route();
      }
    }
  }

  openDialogRegister() {
    this.dialogs.open<number>(new PolymorpheusComponent(RegisterUserComponent)).subscribe();
  }
}
