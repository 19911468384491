<div *rxLet="favoritesState$; let state">
  <button
    #dropdown="nzDropdown"
    nz-dropdown
    nzPlacement="bottomRight"
    nzTrigger="click"
    [nzDropdownMenu]="myFavoritesList"
    class="hover:bg-neutral-100 group flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-gray-500 transition-all duration-300 hover:text-gray-900">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="h-full w-full transition-all duration-300 ease-linear">
      <path
        d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
    </svg>
  </button>
  <nz-dropdown-menu #myFavoritesList>
    <div
      class="max-h-[30em] w-[20rem] divide-y overflow-y-auto overflow-x-hidden rounded-lg !rounded-tr-none border border-gray-300 bg-white shadow-2xl">
      <ng-container *ngIf="state.loaded; else loading">
        <ng-container *ngIf="state.favorites.length; else empty">
          <div class="w-[20rem] px-4 py-5 text-gray-900" *ngFor="let fav of state.favorites">
            <div class="flex w-full items-start">
              <img
                class="h-12 w-12 rounded-lg object-cover"
                [src]="fav.publication.triber.imageUrl"
                alt="{{ fav.publication.triber.name }}" />

              <div class="ml-3 text-sm font-normal">
                <div
                  class="flex w-[14rem] items-center justify-between text-sm font-semibold text-gray-900">
                  <span class="grow truncate">
                    {{ fav.publication.triber.name }}
                  </span>
                  <div class="flex items-center gap-0.5">
                    <div class="flex items-center justify-center">
                      <!-- <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#fcbb14"
                        class="h-[20px] w-[20px]">
                        <path
                          fill-rule="evenodd"
                          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          clip-rule="evenodd" />
                      </svg> -->
                      <!-- <span class="text-xs">{{ fav.publication.stars }}</span> -->
                    </div>
                    <button
                      title="Remover de favoritos"
                      (click)="removeFavorite(fav.publicationId)"
                      type="button"
                      class="ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                      data-dismiss-target="#toast-notification"
                      aria-label="Close">
                      <span class="sr-only">Close</span>
                      <svg-icon
                        class="h-8 w-8"
                        src="assets/images/icons/general/favorite-solid.svg"></svg-icon>
                    </button>
                  </div>
                </div>
                <div class="w-[14rem] truncate text-xs font-normal text-gray-500">
                  {{ fav.publication.title }}
                </div>
              </div>
            </div>
            <div class="mt-3.5 flex items-center justify-between">
              <div class="rounded-[39px] bg-stone-900 px-2 py-1" *ngIf="fav.publication.price">
                <div class="text-xs font-semibold text-white">
                  ${{ fav.publication.price | addComission | addIVA | round }}
                  {{ fav.publication.currency }}
                </div>
              </div>
              <a
                class="w-fit px-3 py-1"
                routerLink="/publicacion/{{ fav.publication.slug }}"
                (click)="dropdown.elementRef.nativeElement.click()"
                tribuuButton
                size="sm"
                theme="primary">
                Agendar sesión
              </a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </nz-dropdown-menu>

  <ng-template #loading>
    <div class="grid min-h-[15rem] w-[20rem] place-content-center">
      <svg
        class="h-10 w-10 animate-spin"
        viewBox="-1.92 -1.92 19.84 19.84"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#7473c0"
        stroke-width="0.5"
        transform="rotate(0)">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="#CCCCCC"
          stroke-width="0.096"></g>
        <g id="SVGRepo_iconCarrier">
          <g fill="#000000" fill-rule="evenodd" clip-rule="evenodd">
            <path
              d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8z"
              opacity=".2"></path>
            <path
              d="M7.25.75A.75.75 0 018 0a8 8 0 018 8 .75.75 0 01-1.5 0A6.5 6.5 0 008 1.5a.75.75 0 01-.75-.75z"></path>
          </g>
        </g>
      </svg>
    </div>
  </ng-template>

  <ng-template #empty>
    <div class="flex min-h-[15rem] w-[20rem] flex-col items-center justify-center gap-2 p-4">
      <svg
        class="h-12 w-12 opacity-50"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
            fill="#404040"></path>
        </g>
      </svg>
      <div class="text-center">
        <p class="text-lg font-semibold text-gray-700">Sin favoritos aún</p>
        <p class="px-4 text-xs text-gray-500">
          Los servicios que marques como favorito se mostrarán aquí.
        </p>
      </div>
    </div>
  </ng-template>
</div>
